import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import Img from "gatsby-image"
import { makeStyles } from "@material-ui/core/styles"
import { Container, Grid, Typography } from "@material-ui/core"

import AccommodationsCategoryCard from "../components/cards/AccommodationsCategoryCard"
import Breadcrumbs from "../components/Breadcrumbs"
import AccommodationMap from "../components/maps/AccommodationMap"
import SEO from "../components/SEO"
import { localizedSlugPrefix } from "../gatsby/nodeHelpers"

const useStyles = makeStyles(theme => ({
  topWrapper: {
    position: "relative",

    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 60px)",
    },
    [theme.breakpoints.up("md")]: {
      height: "calc(100vh - 110px)",
    },
  },
  mainImage: {
    height: "100%",
  },
  topOverlay: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.4)",
    top: 0,
    left: 0,
  },

  pageTitle: {
    [theme.breakpoints.down("sm")]: {},
    color: theme.palette.common.white,
    textTransform: "uppercase",
    fontWeight: 700,
    textAlign: "center",
  },

  intro: {
    marginTop: theme.spacing(3),
    lineHeight: 1.2,
    fontWeight: 700,
    letterSpacing: -1,

    "& p": {
      margin: 0,
    },
  },
}))

const AccommodationsLanding = ({
  data: { accommodationsLanding },
  pageContext: { slug, breadcrumbs, locale, alternateLanguages },
  location,
}) => {
  const classes = useStyles()

  const accommodationsCategories = accommodationsLanding.data.accommodation_categories.map(
    ({ category }) => category.document
  )

  const pins = accommodationsLanding.data.accommodation_categories.map(
    ({ category }) => ({
      latitude: category.document.data.coordinates.latitude,
      longitude: category.document.data.coordinates.longitude,
      title: category.document.data.title.text,
    })
  )

  const [lastBreadcrumb] = breadcrumbs.slice(-1)
  const { path } = lastBreadcrumb
  const canonical = `${process.env.GATSBY_BASE_URL}${path}`

  const meta = {
    title: accommodationsLanding.data.meta_title,
    description: accommodationsLanding.data.meta_description,
    keywords: accommodationsLanding.data.meta_keywords,
    author: "Turist in Transilvania",
  }

  const schema = accommodationsLanding.data.schema_json.text || ""
  const ogp = accommodationsLanding.data.ogp_tags || []

  ogp.push({
    property: "og:image",
    content: accommodationsLanding.data.image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: accommodationsLanding.data.image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: accommodationsLanding.data.image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: accommodationsLanding.data.image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const parsedSchema = JSON.parse(schema)

  parsedSchema["@graph"].push({
    "@type": "ItemList",
    "@context": "https://schema.org/",
    itemListElement: accommodationsCategories.map((category, index) => ({
      "@type": "ListItem",
      name: category.data.title.text,
      position: index + 1,
      item: null,
      url: `${process.env.GATSBY_BASE_URL}/${localizedSlugPrefix(
        category
      )}/${slug}/${category.uid}/`,
    })),
    name: accommodationsLanding.data.title.text,
    description: accommodationsLanding.data.intro.text,
    itemListOrder: "http://schema.org/ItemListOrderAscending",
  })

  parsedSchema["@graph"].push({
    "@type": "Hotel",
    "@context": "http://schema.org",
    description: accommodationsLanding.data.intro.text,
    image: accommodationsLanding.data.image.fluid.src,
    url: canonical,
    name: accommodationsLanding.data.title.text,
    priceRange: "$$",
  })

  return (
    <>
      <SEO
        schema={JSON.stringify(parsedSchema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
        alternateLanguages={alternateLanguages}
      />
      <div className={classes.topWrapper}>
        <Img
          alt={accommodationsLanding.data.image.alt}
          className={classes.mainImage}
          fluid={accommodationsLanding.data.image.fluid}
        />
        <Container maxWidth="sm">
          <Grid
            direction="row"
            justify="center"
            alignItems="center"
            container
            className={classes.topOverlay}
          >
            <Grid item xs={12}>
              <Typography
                component="h1"
                variant="h1"
                className={classes.pageTitle}
              >
                {accommodationsLanding.data.title.text}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Container maxWidth="lg">
        <Grid direction="row" alignItems="center" container spacing={3}>
          <Grid item xs={12} md={6}>
            <Typography
              component="h2"
              variant="h1"
              className={classes.intro}
              dangerouslySetInnerHTML={{
                __html: accommodationsLanding.data.intro.html,
              }}
            />
            <Typography
              variant="body1"
              component="div"
              className={classes.description}
              dangerouslySetInnerHTML={{
                __html: accommodationsLanding.data.description.html,
              }}
            />
          </Grid>

          {accommodationsCategories.length <= 1 &&
            accommodationsCategories.map(category => (
              <Grid item xs={12} md={6} key={category.id}>
                <AccommodationsCategoryCard category={category} />
              </Grid>
            ))}
        </Grid>
      </Container>
      <br />

      {accommodationsCategories.length > 1 && (
        <>
          <Container maxWidth="lg">
            <Grid container direction="row" alignItems="stretch" spacing={2}>
              {accommodationsCategories.map(category => (
                <Grid item xs={12} sm={6} md={4} key={category.id}>
                  <AccommodationsCategoryCard category={category} />
                </Grid>
              ))}
            </Grid>
          </Container>
          <br />
          <br />
        </>
      )}

      <Container maxWidth="lg">
        <AccommodationMap pins={pins} />
      </Container>
      <br />
    </>
  )
}

export default AccommodationsLanding

export const query = graphql`
  query AccommodationsLandingQuery($slug: String!, $locale: String!) {
    accommodationsLanding: prismicAccommodationLanding(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        ogp_tags {
          content
          property
        }
        schema_json {
          text
        }
        meta_title
        meta_description
        meta_keywords
        image {
          alt
          dimensions {
            height
            width
          }

          fluid(maxWidth: 1980, maxHeight: 1070) {
            ...GatsbyPrismicImageFluid
          }
        }
        intro {
          html
        }
        title {
          text
        }
        description {
          html
        }
        accommodation_categories {
          category {
            document {
              ... on PrismicAccommodationCategory {
                id
                data {
                  title {
                    text
                  }
                  subtitle {
                    text
                  }
                  intro {
                    text
                  }
                  coordinates {
                    longitude
                    latitude
                  }
                  image {
                    alt
                    dimensions {
                      height
                      width
                    }

                    fluid(maxWidth: 1980, maxHeight: 1070) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  parent {
                    document {
                      ... on PrismicAccommodationLanding {
                        id
                        uid
                      }
                    }
                  }
                }
                uid
                lang
              }
            }
          }
        }
      }
    }
  }
`

AccommodationsLanding.propTypes = {
  data: PropTypes.shape({
    accommodationsLanding: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
    alternateLanguages: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
